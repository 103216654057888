import React from 'react'
import './Value.css'

function Value({title,number,values}) {
  return (
        <div className=" !w-full flex flex-col items-start justify-center p-5 space-y-5">
            <span className='font-semibold'>{title}<span>(Value No.{number})</span></span>
            <p>{values}</p>
        </div>
  )
}

export default Value