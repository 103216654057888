import React from "react";

const Roadmap = ({ items }) => {
  return (
    <div className="py-10 px-4">
      <div className="max-w-4xl mx-auto space-y-8">
        {items.map((item, index) => (
          <div
            key={index}
            className="group"
            data-aos="fade-up"
            data-aos-delay={`${index * 50}`}
          >
            <div className="flex flex-col items-center text-center">
              {/* Timeline Indicator */}
              <div className="flex items-center justify-center w-10 h-10 bg-gradient-to-tr from-custom-purple to-custom-pink rounded-full shadow-xl transform group-hover:scale-110 transition-transform duration-300 ease-in-out">
                <div className="w-4 h-4 bg-white rounded-full animate-pulse"></div>
              </div>

              {/* Content Box */}
              <div
                className={`mt-5 w-full rounded-xl shadow-2xl p-6 transform group-hover:scale-105 transition-transform duration-300 ease-in-out 
              ${
                index % 2 === 0
                  ? "bg-gradient-to-r from-cyan-500 to-blue-500"
                  : "bg-gradient-to-r from-purple-500 via-pink-500 to-red-500"
              }`}
              >
                <h3 className="text-2xl font-bold underline text-white">
                  {item.title}
                </h3>
                <p className="text-white/90 mt-2">{item.description}</p>
              </div>

              {/* Conditional Line */}
              {index < items.length - 1 && (
                <div className="w-2 h-16 bg-gradient-to-b from-gray-300 to-transparent mt-5 rounded-full"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
