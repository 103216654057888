import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Course.css";
import checktick from "./img/checktick.png";
import crosstick from './img/crosstick.png';
import { cdata } from "./courses";
import RazorpayButton from "../../RazorpayButton"
import RazorpayButton2 from "../../RazorpayButton2";
import RazorpayButton3 from "../../RazorpayButton3"

function Course({ title, plan_type, duration }) {
  const pl = plan_type;
  const navigate = useNavigate();
  const [id, setid] = useState("");

  const hover = () => {
    setid("colored_course");
  };

  const colorref = useRef();
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!colorref.current.contains(e.target)) {
        setid("");
      }
    });
  });

  const selectplan = () => {
    window.plan = pl;
    navigate("/courses/buy");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="course">
      <div className="course_top">
        <h3 id="title">{title} </h3>
        <p id="para">
          Personalized support and advanced study tips that will help your
          career path and scale the future{" "}
        </p>
        <div className="flex flex-col m-auto">
            {plan_type === "Jee" && 
            <div>
              {cdata.map((i) => (
                <div className="">
                  {i.type === "jeeadv" && i.duration === "infinite" &&
                  <div className="flex flex-col m-auto gap-2"> 
                  <span className="mx-auto text-lg font-extrabold text-[#404BFD]">Price ₹{i.price}</span>
                  <RazorpayButton id={i.id} />
                  </div>
                  }
                </div>
              ))}
            </div>}
            {plan_type === "mentorship" && 
            <div>
              {cdata.map((i) => (
                <div>
                {i.type === "mentorship" && i.duration === (duration) && 
                <div className="flex flex-col m-auto gap-2"> 
                <span className="mx-auto text-lg font-extrabold text-[#404BFD]">Price ₹{i.price}</span>
                <RazorpayButton2 id={i.id} />
                </div>
                }
              </div>
              ))}
            </div>}
            {plan_type === "mentorship&testseries" && 
            <div>
              {cdata.map((i) => (
                <div>
                {i.type === "mentorship&testseries" && i.duration === (duration) && 
                <div className="flex flex-col m-auto gap-2"> 
                  <span className="mx-auto text-lg font-extrabold text-[#404BFD]">Price ₹{i.price}</span>
                  <RazorpayButton3 id={i.id} />
                </div>}
              </div>
              ))}
            </div>}
        </div>
      </div>
      <div className="course_bottom flex items-center justify-center mt-5 flex-row">
        <h3 className="margin-">What you will get:</h3>

        <ul className="outside max-w-[80%]">
          {plan_type === "Jee" ? (
            <>
              <li>
                <img src={checktick} alt="tick" />{" "}
                <span>1 to 1  Personal Mentorship by top Ranker IITians</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Jee Advanced Test-Series for practice.</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Strategies to crack JEE-Advanced</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Psychological and Biological Clock Scheduling Help</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Daily personalized checkpoints</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Unlimited Personal Calls with IITians</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>24*7 on Personal Chat Support</span>
              </li>
            </>
          ) : null}
          {plan_type === "mentorship" ? (
            <>
              <li>
                <img src={checktick} alt="tick" />
                <span>1 to 1  Personal Mentorship by top Ranker IITians</span>
              </li>
              <li>
                <img src={crosstick} alt="tick" />
                <span>Jee Mains + Advanced Test-Series for practice</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Strategies to ace difficult topics</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Daily personalized checkpoints</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Unlimited Personal Calls with IITians</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>Experience sharing sessions</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>24*7 on Personal Chat Support</span>
              </li>
            </>
          ) : null}

          {plan_type === "mentorship&testseries" ? (
            <>
              <li>
                <img src={checktick} alt="tick" />
                <span>1 to 1  Personal Mentorship by top Ranker IITians</span>
              </li>
              <li>
                <img src={checktick} alt="tick" />{" "}
                <span>
                Jee Mains + Advanced Test-Series for practice
                </span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>
                Strategies to ace difficult topics
                </span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>
                Daily personalized checkpoints
                </span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>
                Unlimited Personal Calls with IITians
                </span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>
                Experience sharing sessions
                </span>
              </li>
              <li>
                <img src={checktick} alt="tick" />
                <span>
                24*7 on Personal Chat Support
                </span>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    </div>
  );
}

export default Course;
