import React, { useEffect } from "react";
// import { cdata } from "./CourseDetails/Courses/courses";

const RazorpayButton = ({id}) => {
  useEffect(() => {
    // console.log("RazorpayButton rendered with id:", id);
    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = id;
      rzpPaymentForm.appendChild(script);
    }
}, [id]);

  return (
    <div>
      <form className="" id="rzp_payment_form"></form>
    </div>
  );
};

export default RazorpayButton;

