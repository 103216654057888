export const cdata = [
    {
        "id": "pl_Np4SFnHs39qDLi",
        "type": "jeeadv",
        "duration": "infinite",
        "price": "1499"
    },
    {
        "id": "pl_Np4ocGQWGjhywG",
        "type": "mentorship",
        "duration": "one",
        "price": "999"
    },
    {
        "id": "pl_Np52VINI668CAA",
        "type": "mentorship",
        "duration": "three",
        "price": "2499"
    },
    {
        "id": "pl_Np5IiAC1qgPxRB",
        "type": "mentorship",
        "duration": "six",
        "price": "4499"
    },
    {
        "id": "pl_Np5K1Is1unuU5m",
        "type": "mentorship&testseries",
        "duration": "one",
        "price": "1199"
    },
    {
        "id": "pl_Np5OiTmO3ulNXa",
        "type": "mentorship&testseries",
        "duration": "three",
        "price": "2799"
    },
    {
        "id": "pl_Np5QsZzVHmQNYA",
        "type": "mentorship&testseries",
        "duration": "six",
        "price": "4799"
    }
]