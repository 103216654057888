import React, { useEffect, useState } from "react";
import RCarousel from "../Carousel/RCarousel";
import Testimonials from "../Homepage/Testimonials/Testimonials";
import "./CourseDetails.css";
import Course from "./Courses/Course";
import trustimg from "./Courses/img/Trustedby.png";

function CourseDetails() {
  const [duration, setDuration] = useState("three")
  useEffect(() => {
    document.querySelector(".header").style.position = "sticky";
  });
  return (
    <div className="course_details py-10">
      <h1 className="text-5xl font-bold text-[#404BFD]">
        <span className="font-extrabold">Choose A Plan</span> <br/>That&apos;s Right For Your Future
      </h1>

      <div className="flex flex-col items-center justify-center w-full pt-10">
      {/* <span className={`"text-sm sm:text-base border-[#404BFD] text-yellow-500 p-1 border border-b-0 sm:px-2 rounded-t-xl abc" ${duration === "three" && "bg-gradient-to-r from-[#CC00FF] to-[#52B0F4] rounded-t-xl !text-white"}`}>Most Popular</span> */}
      <div className="w-fit rounded-3xl flex items-center justify-center border border-blue-500">
        <button className={`${duration === "one" ? "text-white sm:text-xl bg-gradient-to-r from-[#CC00FF] to-[#52B0F4] rounded-3xl py-4 sm:py-6 sm:px-16 px-3 font-bold" : "py-4 sm:py-6 sm:text-xl px-3 sm:px-16 rounded-3xl text-black font-bold"}`} onClick={() => setDuration("one")}>
          1 Month
        </button>
        <button className={`${duration === "three" ? "text-white sm:text-xl bg-gradient-to-r from-[#CC00FF] to-[#52B0F4] rounded-3xl py-4 sm:py-6 px-3 sm:px-16 flex flex-col items-center justify-center font-bold" : "py-3 sm:py-5 sm:text-xl px-3 sm:px-16 rounded-3xl text-black flex flex-col items-center justify-center font-bold"}`} onClick={() => setDuration("three")}>
          <span className="text-xs -mt-3 text-yellow-500">Most popular</span>
          <span>3 Months</span>
        </button>
        <button className={`${duration === "six" ? "text-white sm:text-xl bg-gradient-to-r from-[#CC00FF] to-[#52B0F4] rounded-3xl py-4 sm:py-6 px-3 sm:px-16 font-bold" : "py-3 sm:py-5 sm:text-xl px-3 sm:px-16 rounded-3xl text-black font-bold"}`} onClick={() => setDuration("six")}>
          6 Months
        </button>
      </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center pt-20 space-y-5 lg:space-y-0 lg:space-x-5 px-10 xl:space-x-10">
        <div className="flex flex-col items-center justify-center ef:-mt-8 w-[550px] lg:w-[400px]">
          <span className="bg-gradient-to-r from-[#CC00FF] to-[#52B0F4] text-white p-1 px-2 rounded-t-xl">Most Sold</span>
        <Course
          title="JEE Advanced 2024"
          id="colored_course"
          plan_type={"Jee"}
          duration={duration}
        />
        </div>
        <div className="w-[550px] flex items-center justify-center lg:w-[400px]">
          <Course title="Mentorship" id="colored_course" plan_type={"mentorship"} duration={duration} />
        </div>
        <div className="w-[550px] flex items-center justify-center lg:w-[400px]">
          <Course title="Mentorship + Test Series" plan_type={"mentorship&testseries"} duration={duration} />
        </div>
      </div>

      {/* <div className="Trustedby">
              <h1>Trusted by thousands of students.<img src={trustimg} alt="" /></h1>
              <p>We have given more than 5k students a platform to make it into their dream institutions. Even in our infancy we have ensured what many can&apos;t do in years – concrete results. And in doing that we have ensured that our students trust us – because we trust them. A mentorship is also a partnership, and as partners in this quest for achieving our dreams trust is the bedrock, the foundation.</p>
            </div>

            <RCarousel/> */}
    </div>
  );
}

export default CourseDetails;
