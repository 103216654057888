import React from 'react'

function Terms_conditions() {
  return (
    <div className='flex flex-col items-start justify-center px-10 py-10 sm:px-20 md:px-32 space-y-5'>
      <h1 className='text-3xl font-semibold'>Terms & conditions</h1>
      <p>
        All Users who utilise the Platform are subject to this privacy statement, thus before providing any Personal Information, Users must read and understand the statement (defined hereinafter). This privacy statement applies to the "IITianBuddy" application, the IITianBuddy YouTube channel, the website, as well as other associated applications, products, websites, and services administered by IITianBuddy. You agree to the use and processing of your Personal Information in accordance with this Policy by providing it. IITianBuddy is not liable or responsible for any third-party websites that download their own cookies or other files onto users' computers, gather data, or ask for personal information from users. IITianBuddy does not guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages, or other materials available on such Website/Applications and does not make any representations regarding the privacy practises or policies of such third parties or the terms of use of such Website. The inclusion or exclusion does not suggest that IITianBuddy endorses the website, IITianBuddy that hosts it, or the content it contains. The user is advised by IITianBuddy to read the privacy policies of each such Website/Application and acknowledges that only such third parties are accountable to the user in this regard. IITianBuddy has implemented industry standards and taken reasonable precautions as required by applicable Indian law to treat Personal Information as confidential and to guard it against unauthorised access, improper use or disclosure, unauthorised modification, unlawful destruction, or accidental loss. We won't use or disclose your information in any other way than what is stated in this privacy statement. You accept the practises outlined in this Privacy Policy at the time you use our Service each time.
        IITianBuddy does not guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages, or other materials available on such Website/Applications and does not make any representations regarding the privacy practises or policies of such third parties or the terms of use of such Website. The inclusion or exclusion does not suggest that IITianBuddy endorses the website, IITianBuddy that hosts it, or the content it contains. The user is advised by IITianBuddy to read the privacy policies of each such Website/Application and acknowledges that only such third parties are accountable to the user in this regard.
        IITianBuddy has implemented industry standards and taken reasonable precautions as required by applicable Indian law to treat Personal Information as confidential and to guard it against unauthorised access, improper use or disclosure, unauthorised modification, unlawful destruction, or accidental loss. We won't use or disclose your information in any other way than what is stated in this privacy statement. You accept the practises outlined in this Privacy Policy at the time you use our Service each time.

      </p>

    </div>
  )
}

export default Terms_conditions