import { useEffect } from "react";
import PropTypes from "prop-types";
import Axios from "axios";

const loadScript = (src) =>
  new Promise((resolve) => {
    // Check if script is already loaded
    if (document.querySelector(`script[src="${src}"]`)) {
      console.log("Razorpay SDK is already loaded.");
      resolve(true);
      return;
    }

    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.log("Razorpay SDK loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.log("Error loading Razorpay SDK");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({ orderId, keyId, currency, amount }) => {
  console.log("hello");
  console.log(orderId+ keyId + currency + amount);
  const serverBaseUrl = "http://localhost:5000";

  useEffect(() => {
    const displayRazorpay = async () => {
      const isLoaded = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!isLoaded) {
        alert("Failed to load payment gateway. Are you online?");
        return;
      }
      console.log(isLoaded);
      const options = {
        key: keyId,
        amount: amount.toString(),
        currency,
        name: "IITian Buddy",
        order_id: orderId,
        handler: async (response) => {
          try {
            console.log(response);
            const paymentResult = await Axios.post(
              `${serverBaseUrl}/payment/success`,
              {
                orderId: response.razorpay_order_id,
                paymentId: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              }
            );
            console.log("Payment verification response:", paymentResult.data);
          } catch (error) {
            console.error("Payment verification failed:", error);
          }
        },
       
        modal: {
          ondismiss: () => {
            console.log("Checkout modal closed");
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    displayRazorpay();
  }, [orderId, keyId, currency, amount]);

  return null;
};

RenderRazorpay.propTypes = {
  orderId: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default RenderRazorpay;
