import React from 'react'

function Services({image,heading,desc}) {
    return (
      <div>
        <div className="st_wtfy_c cursor-none ">
          <img src={image} alt="not found" />
          <div className="st_wtfy_c_desc cursor-none">
            <h3 className="text-lg font-semibold ">{heading}</h3>
            <span className="">{desc}</span>
          </div>
        </div>
      </div>
    );
}

export default Services