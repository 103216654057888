import React, { useEffect } from "react";
import "./homepage.css";
import illImg from "../img/ill_img.png";
import Services from "./subcomponents/Services";
// import Testimonials from "./Testimonials/Testimonials";
import FAQ from "./FAQ/FAQ";
// import Mentor from "./Mentor/Mentor";
import Footer from "../Footer/Footer";
// import Mentordetails from "./data/Mentors.json";
import Servicedetails from "./data/Services.json";
// import Mentorshipdetails from "./data/Mentorshipdetails.json";
import faq from "./data/Faq.json";
// import arrow from "./arrow-right.png";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// import Mentorshipdesc from "./subcomponents/Mentorshipdesc";
// import Carousel from "../Carousel/RCarousel";
// import RCarousel from "../Carousel/RCarousel";
import MCarousel from "../Carousel/MCarousel";
import { useNavigate } from "react-router-dom";
import user from "./users.png";
import star from "./stars.png";
import Typewriter from "typewriter-effect";
// import Timeline from "./Timeline.js";
import Roadmap from "./Timeline.js";
import ClientCard from "./Testimonials/TestimonialCard.js";
import MentorCards from "./Testimonials/MentorCarousel.js";
const items = [
  {
    title: "Mentor Allotment",
    description: "Instant Assignment of your personal mentor from IIT",
  },
  {
    title: "Get a Personalised study schedule",
    description:
      "A customized study plan design by your mentor with the aim to get the most out of your time.",
  },
  {
    title: "Everyday Tasks",
    description:
      "Assignment of daily goals to improve your focus and productivity.",
  },
  {
    title: "Doubt Clearance",
    description:
      "The solution to all doubt in your inquisitive mind to keep you thinking and not just blinking.",
  },
  {
    title: "Get Dream IIT",
    description: "Your hard work will pay and you will get success and glory.",
  },
  // Add more items as needed
];
function Homepage() {
  useEffect(() => {
    document.querySelector(".header").style.position = "sticky";
  });

  const navigate = useNavigate();

  return (
    <>
      <div className="homepage overflow-x-hidden overflow-y-hidden bg-white">
        {/* home animation */}

        <div className="l_illus flex w-full items-center justify-between ef:py-10 cd:py-20">
          <div className="l_illus_left text-center w-full flex items-center justify-start h-full sm:justify-center pt-20 sm:pt-0 flex-col space-y-3 ef:px-5">
            <h1 className="">
              1-to-1 live{" "}
              <span className="font-bold">
                Personal <br /> Mentorship
              </span>
              <div
                style={{ display: "flex" }}
                className="text-red-500 font-bold flex items-center justify-center w-full"
              >
                {" "}
                <span className="text-black font-normal">by&nbsp;</span>
                <Typewriter
                  options={{
                    strings: ["IITians", "Rankers"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </h1>
            {/* <p>Looking for a mentor who can guide you through your goals of getting into IIT' or AIIMS...???
Fortunately you Landed up at a right spot. Not only a mentor, we try our best efforts to provide you an elder brother or sister who have been into your shoes once, qualified JEE or AIIMS, got into dream colleges and fulfilled their goals. They help you personally on daily basis and put their best efforts to assist you at each steps of ladder of your goals.</p> <br /> */}
            <button
              className="py-2 hover:bg-[#474EFA60] bg-opacity-50 text-[#404BFD] hover:te font-semibold rounded-xl ring-2 hover:ring-[#9A72DD] shadow-lg shadow-[#9A72DD] ring-[#404BFD] flex items-center justify-center"
              id="h_ge"
              onClick={() => navigate("/courses")}
            >
              {" "}
              <span>Get Enrolled</span>
            </button>
          </div>
          <div className="l_illus_right hidden sm:block lg:w-3/4">
            <img src={illImg} alt="not found" />
          </div>
        </div>
        {/* home animation */}
        {/* Why Us section */}
        <div className="flex w-full items-center justify-center flex-col space-y-2 -mt-64 sm:-mt-0 pt-10 pb-5">
          <span className="text-4xl sm:text-5xl font-bold text-[#404BFD] text-center">Why Us ?</span>
          <span className="text-lg sm:text-xl font-semibold text-green-500 text-center">
            {" "}
            Learn from the real IITians not from any random Bhaiya and Didi.
          </span>
        </div>

        {/* Things we take care for you  */}
        <div className="st_wtfy">
                    {/* <h1 className='text-3xl text-center sm:text-4xl font-bold text-[#404BFD]'>Something's We Take Care For You </h1> */}
                    <div className="st_wtfy_ct">
                        <div className="st_wtfy_ct_left">
                            {/* <Services image={} heading="Notes & Study Material" desc="Prescribe notes based on NCERT syllabus(Mentors will provide their own short notes if needed)"/> */}

                            {Servicedetails.slice(0, 3).map((sdetails, index) => {
                                return (
                                    <div>

                                        <Services
                                            heading={sdetails.heading}
                                            desc={sdetails.desc}
                                            image={sdetails.img}
                                        />
                                    </div>
                                );
                            })}

                        </div>
                        <div className="st_wtfy_ct_right">

                            {/* <Services image={pencil}/> */}
                            {Servicedetails.slice(3, 6).map((sdetails, index) => {
                                return (
                                    <div>

                                        <Services
                                            heading={sdetails.heading}
                                            desc={sdetails.desc}
                                            image={sdetails.img}
                                        />
                                    </div>
                                );
                            })}

                        </div>
                    </div>
                    {/* Things we take care for you  */}
                    {/* how mentorship works   */}
                    {/* <div className="H_men_w">
                        <div className="H_men_w_container">
                            <h1 className='text-5xl font-bold text-[#404BFD]'>How Mentorship Works</h1>
                            <br /><br />
                            <div className="H_men_w_content">

                                {Mentorshipdetails.map((m_details, index) => {
                                    return (
                                        <div>

                                            <Mentorshipdesc
                                                heading={m_details.heading}
                                                desc={m_details.desc}
                                                image={m_details.img}
                                            />
                                            {/* {pdetail.sno}. I am  my hobby is {pdetail.hobby} */}
                                        {/* </div>
                                    );
                                })}
                            </div>
                            <br /><br /><br />
                        </div> */}
                    {/* </div> */}
                    {/* how mentorship works   */}

                    {/* Mentor details */}

                    {/* <div className="m_y_mentors">
                        <h1>Meet Your Mentors</h1>
                        <p>We have the best team of mentors from IIT/AIIMS that will make you reach your dream college!</p>
                    </div>
                    <MCarousel carouselinput={"mentors"} /> */}

                    {/* {Mentordetails.map((mdata)=>{
                                return <>
                                <Mentor name={mdata.name} img={mdata.img} desc={mdata.desc} quote={mdata.quote} />
                                </>
                              })}   */}

                    {/* Mentor details */}

                    {/* mentee testimonials */}
                    {/* {window.innerHeight >= 900? <h1 style={{marginTop:"50px",marginBottom:"50px"}} className='text-5xl font-bold text-[#404BFD]'>See What Our Mentees Say </h1>: <h1 style={{marginTop:"10px",marginBottom:"40px"}} className='text-5xl font-bold text-[#404BFD]'>See what our mentees say </h1>}

                    <RCarousel carouselinput={"mentee_review"} /> */}



                    {/* mentee testimonials */}
                </div>

        <div className="o_reviews items-center justify-center flex-col xl:h-[300px] mt-20">
          {/* <div className="o_reviews_bx text-2xl font-semibold pb-5" id='o_rev_stmt'>
                            We Speak With Our Full Statistics
                        </div> */}
          <div className="flex items-center flex-col lg:flex-row justify-between w-full">
            <div className="flex items-center justify-between w-full flex-col md:flex-row">
            <div className="o_reviews_bx">
              <h1>
                <VisibilitySensor>
                  {({ isVisible }) => (
                    <div>
                      {isVisible ? <CountUp end={15} decimals={0} /> : "0"}k+
                    </div>
                  )}
                </VisibilitySensor>
              </h1>
              <p className="flex items-center justify-center space-x-2">
                {" "}
                <img src={user} alt="not" style={{ marginRight: "5px" }} />
                Students Enrolled
              </p>
            </div>
            <div className="o_reviews_bx">
              <h1>
                <VisibilitySensor>
                  {({ isVisible }) => (
                    <div>
                      {isVisible ? <CountUp end={11} decimals={0} /> : "0"}k+
                    </div>
                  )}
                </VisibilitySensor>
              </h1>
              <p className="flex items-center justify-center space-x-2">
                {" "}
                <img src={star} alt="not" style={{ marginRight: "5px" }} />
                Students Selected
              </p>
            </div>
            </div>
            {/* <div className="o_reviews_bx">
                            <h1>
                                <VisibilitySensor>
                                    {({ isVisible }) =>
                                        <div>{isVisible ? <CountUp end={5} /> : '0'}M+</div>
                                    }
                                </VisibilitySensor>
                            </h1>
                            <p className='flex items-center justify-center space-x-2'> <img src={user} alt="not" style={{marginRight:"5px"}} />Total Views</p>

                        </div> */}
            <div className="flex items-center justify-between w-full flex-col md:flex-row">
            <div className="o_reviews_bx">
              <h1>
                <VisibilitySensor>
                  {({ isVisible }) => (
                    <div>{isVisible ? <CountUp end={3} /> : "0"}00K+</div>
                  )}
                </VisibilitySensor>
              </h1>
              <p className="flex items-center justify-center space-x-2">
                {" "}
                <img src={star} alt="not" style={{ marginRight: "5px" }} />
                Hours Mentored
              </p>
            </div>
            <div className="o_reviews_bx">
              <h1>
                <VisibilitySensor>
                  {({ isVisible }) => (
                    <div>{isVisible ? <CountUp end={200} /> : "0"}+</div>
                  )}
                </VisibilitySensor>
              </h1>
              <p className="flex items-center justify-center space-x-2">
                {" "}
                <img src={star} alt="not" style={{ marginRight: "5px" }} />
                IITian Mentors
              </p>
            </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        {/* our reviews */}

        <div className="v_desc pb-10">
          <div className="flex w-[90%] flex-col sm:flex-row space-y-5 sm:space-y-0 items-center justify-between h-full">
            <iframe
              src="https://www.youtube.com/embed/AMqASlV2TjQ?si=XQA-NWmq4Q3OQlcP?&autoplay=1&mute=1"
              className="w-full h-full"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <div className="flex sm:flex-col items-center justify-center space-x-5 pt-3 sm:pt-0 sm:space-x-0 sm:space-y-10 sm:pr-10 lg:pr-32">
              <div className="flex flex-col items-center justify-center">
                <span className="text-2xl sm:text-3xl font-semibold text-[#404BFD]">
                  50 Lakhs+
                </span>
                <span className="text-lg">Views</span>
              </div>
              <div className="flex flex-col items-center justify-center">
                <span className="text-2xl sm:text-3xl font-semibold text-[#404BFD]">
                  25K+
                </span>
                <span className="text-lg">Subscribers</span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex w-full items-center justify-center flex-col space-y-3">
          <span className="text-4xl text-center sm:text-5xl font-bold text-[#404BFD]">
            Your Journey to IIT 🚀{" "}
          </span>
          <span className="text-lg sm:text-xl text-center font-semibold mb-3 text-green-500 ">
            {" "}
            Follow the Roadmap that leads to success.
          </span>
          <br />
        </div>
        <Roadmap items={items} />
        <div className="w-full flex items-center justify-center pt-5">
        <span className="text-4xl text-center sm:text-5xl font-bold text-[#404BFD]">Students' Reviews</span>
        </div>
        <ClientCard />
        <div className="w-full flex items-center justify-center">
        <span className="text-4xl text-center sm:text-5xl font-bold text-[#404BFD]">Mentors' Message</span>
        </div>
        <MentorCards />
      </div>

                <div className="w-screen flex items-center justify-center flex-col">
                    <h1 className='text-4xl sm:text-5xl font-bold text-[#404BFD] text-center'>Frequently Asked Questions</h1>
                    <div className="faq_container w-[70%]">

                        {faq.map((data) => {
                            return <>
                                <FAQ question={data.question} answer={data.answer} />

                            </>
                        })}
                    </div>
                    <br />

                    <Footer screen={"homepage"} />
                    </div>
                <div>
            </div>
    </>
  );
}

export default Homepage;
