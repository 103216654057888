import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Buy.css'
import Pay from './PayNowModal/Pay';
// import { AmountContext } from '../../Context/AmountContext';
import planimg from './plan_page.png'
import Plans from '../Homepage/data/Plans.json'

function Buy({ }) {

  const navigate = useNavigate();

  useEffect(() => {

    if (window.plan) {
      
      navigate("/courses/buy")

    } else {
      navigate("/courses")
    }
  }, [])


  return (<>

    <div className='Buy_course'>
      {/* <h1>Lets make this your best investment</h1> */}
      <h1 className='text-5xl font-bold text-[#404BFD]'>Buy Courses For {window.plan} Mentoring</h1>
      <div className="plans">
        <div className="plans_left">
          <img src={planimg} />
        </div>
        <div className="plans_right grid grid-cols-1 lg:grid-cols-2 space-y-1 lg:space-y-0">
          
          {Plans.map((plan)=>{
            return <>
             <div className="plan flex items-center justify-center">
            <span className='!h-fit flex items-center justify-center flex-col'>
              <span>
              {plan.amount}/{plan.duration}
              </span> 
            <span style={{position:"relative",left:"1%",fontSize:"12px",color:"red"}}>
               <span style={{textDecoration:"line-through"}}>
                {plan.amount + Math.round(plan.amount*0.1)}
                </span> /- 10% off
              </span> 
            </span>
            <Pay amount={plan.amount} plan_duration={plan.duration} subscription_days={plan.subscription_days}  />
          </div>
            </>
          })}

        </div>
      </div>
      <div className="buy_course_msg">
        <h1 className='text-5xl font-bold text-[#404BFD]'>What Ever You Choose We Are With You</h1>
        <p>
          Things have to change, to adapt, to embrace and invent new approaches and ideas. Stagnant
          water smells.
          Someone that doesn't adapt and improve goes extinct in this fast-changing world.
          So why not us? We embrace change and improvement. New plans, new approaches, new
          courses and mentors.
          New formats. But what will NOT change is our core philosophy. What will not change is
          that you, the student, will be at the center of it all. What will not change is our philosophy.
        </p>
      </div>

    </div>
  </>
  )
}

export default Buy