import React, { useState } from "react";
// import { cdata } from "./ClientData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mdata from "../data/Mentors.json";

function MentorCards() {
  const [showMore, setShowMore] = useState(false);

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    initialSlide: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="py-10 w-screen slider-container">
      <Slider {...settings}>
        {mdata.map((i, id) => (
          <div
            key={id}
            className="font-poppins bg-[#FFFFFF24] p-5 rounded-2xl mt-10 border border-blue-500 md:h-[250px] hover:scale-105 ease-out duration-200 mb-10"
          >
            <div className="flex items-center justify-between">
              <div className="space-y-3">
                <div className="flex space-x-3">
                  <div className="rounded-full">
                    <img
                      className="w-24 h-14 rounded-full"
                      src={
                        i.img
                          ? i.img
                          : "https://img.icons8.com/ios/50/user-male-circle--v1.png"
                      }
                      alt="user"
                    />
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <span>{i.name}</span>
                    <span className="text-slate-500 text-sm">{i.desc}</span>
                  </div>
                </div>
                {/* <div className="flex items-center space-x-1">
                  <img className="w-5" src="Star.png" alt="star" />
                  <img className="w-5" src="Star.png" alt="star" />
                  <img className="w-5" src="Star.png" alt="star" />
                  <img className="w-5" src="Star.png" alt="star" />
                  <img className="w-5" src="Star.png" alt="star" />
                  <span>5.0</span>
                </div> */}
              </div>
              <div>
                <img className="w-24" src="quote-down.png" alt="quotes" />
              </div>
            </div>
            <div className="max-w-xl pt-7">
              <span className="">
                {i.quote}
                {/* <button className="text-[#404BFD]" onClick={() => setShowMore(!showMore)}>
                  {showMore === false ? <span>...Show More</span> : <span>&nbsp;Show Less</span>}
                </button> */}
              </span>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default MentorCards;
