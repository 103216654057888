import React from 'react'
import './Socials.css'
import instagram from './img/instagram.png'
import telegram from './img/telegram.png'
import whatsapp from './img/whatsapp.png'
import youtube from './img/youtube.png'
import gmail from './img/gmail.png'


function Socials() {
  return (
    <div className='socials'>
        <a href="https://www.instagram.com/tushar.money/" target="_blank" ><img src={instagram} alt="" /></a>
        <a href="https://t.me/Tushar_IITBHU" target="_blank" ><img src={telegram} alt="" /></a>
        <a href="https://wa.me/9125999708" target="_blank" ><img src={whatsapp} alt="" /></a>
        <a href="https://youtube.com/@TusharIIT?si=znq8FJsKa5ALivvP" target="_blank"><img src={youtube} alt="" /></a>
        <a href="mailto:contact@iitianbuddy.com"><img src={gmail} alt="" /></a>
    </div>
  )
}

export default Socials