import React, { useEffect, useState } from "react";
import axios from "axios";
import RenderRazorpay from "./RendorRazorpay"; // Corrected the file name as per your note

function Test() {
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const [refresh, setRefresh] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });

  const serverBaseUrl = "http://localhost:5000";

  const handleCreateOrder = () => {
    axios
      .post(`${serverBaseUrl}/payment/order`, {
        subscriptionPlanId: "6605007b7bd155ca9d031006",
        contact: "6376592767",
      })
      .then((response) => {
        const data = response.data;
        console.log(data);
          if (data && data.id) {
              setOrderDetails({
                  orderId: data.id,
                  currency: data.currency,
                  amount: data.amount,
              });
          }
            setDisplayRazorpay(true);
            
            console.log(orderDetails);
            
        
      })
      .catch((error) => {
        console.error("Error creating order:", error);
      });
  };
    
  return (
    <div className="w-full flex items-center justify-center h-full">
      <button
        className="border-2 border-black rounded-full p-3"
        onClick={handleCreateOrder}
      >
        Pay
      </button>
      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
        />
      )}
    </div>
  );
}

export default Test;
