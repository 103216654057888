import React, {useEffect} from 'react'

function RazorpayButton2({id}) {
    useEffect(() => {
        // console.log("RazorpayButton rendered with id:", id);
        const rzpPaymentForm = document.getElementById("rzp_payment_form2");
    
        if (!rzpPaymentForm.hasChildNodes()) {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/payment-button.js";
          script.async = true;
          script.dataset.payment_button_id = id;
          rzpPaymentForm.appendChild(script);
        }
    }, [id]);
    
      return (
        <div>
          <form className="" id="rzp_payment_form2"></form>
        </div>
      );
    };

export default RazorpayButton2