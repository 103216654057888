import React, { useEffect, useState } from "react";
import "./About.css";
import about_main from "./img/about_main.png";
import our_message from "./img/our_message.png";
import mentor1 from "./img/mentor1.png";
import Mentor from "./Mentor/Mentor";
import Value from "./Value/Value";
import Contactformdetails from "./data/Contact Information (Responses) - Form responses 1.json";
import Footer from "../Footer/Footer";

function Aboutus() {
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    document.querySelector(".header").style.position = "sticky";
  });

  return (
    <div className="bg-white">
      <div className="bg-white pt-20">
        {/* about main  */}

        <div className="about_main">
          <div>
            <h2 style={{ fontSize: "25px", marginBottom: "50px" }}>
              "Mentoring is a brain to pick, an ear to listen, and a push in the
              right direction" - Jonh C. Crosby
            </h2>

            <p>
              You make commitments to start things, but without consistency, you
              never reach the finishing line; though you may be on the last
              step, but you quit. We at IITianBuddy never let you quit and help you at
              every step to maintain consistency to achieve your goal and get to
              your dream college.
            </p>
          </div>
          <img src={about_main} alt="not found" />
        </div>

        <div className="flex flex-col text-[#383838] items-center justify-center space-y-5">
          {/* <span className="text-black font-semibold text-xl sm:text-3xl tracking-wider">
          About Us
        </span> */}
          <div className="w-[80%] flex flex-col space-y-10 items-center justify-center pt-10">
            <div className="flex items-center sm:items-start justify-between flex-col sm:flex-row">
              <div className="p-5 md:w-1/2 order-2">
                <p>
                  Flooded with resources? Enrolled in top coaching institutes?
                  Tried out all possible strategies? Still unable to ace
                  competitive exams? Then the only thing you lack is
                  mentorship in new direction.IIT-JEE, NEET, AIIMS, etc., are
                  not just examinations. Instead, they are roads to glory, and
                  to achieve what only the top 1% can get; one needs something
                  more than just hard work and dedication, i.e.,
                  mentorship in new direction. IITianBuddy is an IIT-BHU-based
                  mentorship start-up whose goal is to help students who aspire
                  to become IITians or Doctors by providing them with the proper
                  guidance and study plans.We, at IITianBuddy, have a
                  well-established team of over 200 mentors who themselves are
                  from top-notch IITs, AIIMS, and NEET colleges and are willing
                  to spare their precious time to help all the students who
                  crave to get into these institutes and put the right step in
                  the direction of a great and prosperous future. At
                  IITianBuddy, each aspirant is assigned a personal mentor who,
                  as an elder brother/sister,will be helping the student at
                  every stage of their preparation. The mentors even provide
                  them with daily tasks and resources and, most importantly,
                  share their experiences of cracking these entrance exams. We
                  are proud to announce that we have mentored over 5k students
                  to get into their dream institutions through our mentorship
                  program and are looking forward to helping more and more
                  students daily.
                </p>
              </div>
              <div className="p-5 text-lg sm:text-2xl font-semibold md:w-1/2 order-1">
                <h1 className="text-5xl font-bold text-[#404BFD]">
                  Something About Us
                </h1>
              </div>
            </div>
            <div className="w-full items-start justify-center space-y-5">
              <h1 className='text-3xl sm:text-5xl font-bold text-[#404BFD]'>For Us, Our Value Are More Than Just <br /> Words.  They Are Ways Of Living, Creating <br /> And Doing.</h1>
              <h3 className="text-xl sm:text-3xl font-bold text-[#404BFD]">
                Our Family Values
              </h3>
            </div>
            <div className="flex flex-col items-center justify-between space-y-5">
              <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 items-center justify-between md:space-x-5">
              <div className="flex items-center justify-between md:items-start text-sm w-full md:w-1/2">
              <Value
                title="Trust"
                number={1}
                values={
                  "Building trust between a mentor and student is the most important thing when it comes to our programmes. We value your time and our integrity. Trust between the mentors, with the management and most important of all the trust between the mentor and the student is what our programmes are and will continue to be based on."
                }
              />
              </div>
              <div className="flex flex-col items-center justify-between md:items-start text-sm w-full md:w-1/2">
              <Value
                title="Love What We Do"
                number={2}
                values={
                  "During the time that other students are partying, playing video games, going on trips, here's a bunch of students that want to improve the future of hundreds of thousands of students. And the only reason for that is - love for what we do. Passion. We toil for hours because in the end this is our calling, this is the reason we wake up in the morning and the only thing that makes us genuinely happy."
                }
              />
              </div>
              </div>
              <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 items-center justify-between md:space-x-5">
              <div className="flex flex-col items-center justify-between md:items-start text-sm w-full md:w-1/2">
              <Value
                title="Open Communication"
                number={3}
                values={
                  "This world revolves around interactions. Between friends, students, mentors, it all comes down to how we communicate. Openly communicating our thoughts and ideas is what made this start-up happen, and it will remain the guiding principle of everything we do. The only way to make a change happen is to communicate without fear, and with trust."
                }
              />
              </div>
              <div className="flex flex-col items-center justify-between md:items-start text-sm w-full md:w-1/2">
              <Value
                title="Honesty"
                number={4}
                values={
                  "We have gone through the journey ourselves. We know how other institutes and programmes are designed to rip you off, to scam you, so that they can make a quick buck. But not us. We are transparent and honest about everything that goes on within us. No veils on our working, no dishonesty in our words."
                }
              />
              </div>
              </div>
            </div>
          </div>
        </div>

        {/*Our values*/}
        {/* <div className="our_values">
          <h1 className="text-5xl font-bold text-[#404BFD]">
            For Us, Our Value Are More Than Just <br /> Words. They Are Ways Of
            Living, Creating <br /> And Doing.
          </h1>
          <div className="values flex flex-col items-center justify-center w-full">
            <div className=" pt-10 flex items-center justify-start w-[80%]">
              <h3 className="text-4xl sm:text-5xl font-bold text-[#404BFD]">
                Our Family Values
              </h3>
            </div>
            <hr />
            <div className="flex flex-col items-center justify-center py-10 w-full">
              <Value
                title="Trust"
                number={1}
                values={
                  "Building trust between a mentor and student is the most important thing when it comes to our programmes. We value your time and our integrity. Trust between the mentors, with the management and most important of all the trust between the mentor and the student is what our programmes are and will continue to be based on."
                }
              />
              <Value
                title="Love What We Do"
                number={2}
                values={
                  "During the time that other students are partying, playing video games, going on trips, here's a bunch of students that want to improve the future of hundreds of thousands of students. And the only reason for that is - love for what we do. Passion. We toil for hours because in the end this is our calling, this is the reason we wake up in the morning and the only thing that makes us genuinely happy."
                }
              />
              <Value
                title="Open Communication"
                number={3}
                values={
                  "This world revolves around interactions. Between friends, students, mentors, it all comes down to how we communicate. Openly communicating our thoughts and ideas is what made this start-up happen, and it will remain the guiding principle of everything we do. The only way to make a change happen is to communicate without fear, and with trust."
                }
              />
              <Value
                title="Honesty"
                number={4}
                values={
                  "We have gone through the journey ourselves. We know how other institutes and programmes are designed to rip you off, to scam you, so that they can make a quick buck. But not us. We are transparent and honest about everything that goes on within us. No veils on our working, no dishonesty in our words."
                }
              />
            </div>
            <div className="value_head"></div>
          </div>
        </div> */}

        <div className="our_message py-20">
          <h1 className="text-5xl font-bold text-[#404BFD]">
            We Believe Supporting Personal Development Creates A Positive
            Culture And Environment For Everyone.
          </h1>
          <img src={our_message} alt="not found" />
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
