import React from "react";
import { useNavigate } from "react-router-dom";
// import './Footer.css'
// import footer from './footer/footer-bg.png'
// import instagram from './footer/img/instagram.png'
// import telegram from './footer/img/telegram.png'
// import whatsapp from './footer/img/whatsapp.png'
// import youtube from './footer/img/youtube.png'
// import gmail from './footer/img/gmail.png'
// import arrow from './footer/img/arrow-right (1).png'
import logo from "../Header/Header/img/TransparentLogo.png";

function Footer({ screen }) {
  const navigate = useNavigate();
  const navigateto = (path) => {
    console.log("clicked");
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const scrolltotop = () => {};
  return (
    <>
      <div className="flex flex-col items-center justify-center w-full !pt-0 !mt-0 h-fit">
        {/* <div className='footer w-full flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat py-7 mb-16 sm:mb-0'> */}
        {/* <img src={footer} alt="footerImage" /> */}
        {/* <div className="footer_msg space-y-4 flex flex-col items-center justify-center w-full">
            <h2>If You Cannot See Where You Are Going, Ask Someone Who Has <br /> Been There Before.</h2>
            <div className='flex items-center justify-center flex-col sm:flex-row sm:space-x-20 w-full h-full'>
                <button id="enroll" onClick={()=>navigateto("/courses")} className='flex items-center justify-center px-5 sm:px-10 sm:py-3 mb-3'><span>Enroll now </span><img src={arrow} alt="" /></button>
                <button id='talk' className='!py-0 !my-0'><a href="tel:+919125999708" style={{color:"white"}} className='flex items-center justify-center px-3 sm:px-10 sm:py-3'>Talk with your Mentor</a></button>
            </div>
        </div> */}
        {/* {screen === "homepage"?
        <div id='footer-back' style={{background:"black",height:"200px",width:"100%",zIndex:"-1",position:"relative",top:"-50%"}}></div>
     :
     <div id='footerback' style={{background:"black",height:"250px",width:"100%",zIndex:"-1",position:"relative",top:"-90%"}}></div>
     
     } */}

        {/* </div> */}
        <div
          className="footer_bo flex items-center justify-center w-full h-fit py-7 bg-black"
          id={screen}
        >
          <div className="footer_to flex flex-col md:flex-row space-y-5 md:space-y-0 items-start h-full justify-between w-[60%] !pt-0 !mt-0">
            <div className="!pt-0">
              <div className="f_smico flex flex-col items-start justify-center">
                <img
                  className=""
                  src={logo}
                  alt=""
                  style={{ width: "90px", height: "50px" }}
                />
                <a
                  href="tel:+919125999708"
                  style={{ color: "white", display: "block" }}
                  className="pt-2"
                >
                  {" "}
                  Ph:+91 9125999708
                </a>
                <a
                  href="mailto:support@boosturmind.com"
                  style={{
                    color: "white",
                    display: "block",
                    width: "max-content",
                  }}
                >
                  Email: contact@iitianbuddy.com
                </a>
              </div>
            </div>
            <div className="footer_mi flex items-center justify-start text-white">
              <div className="f_compan flex items-center justify-center">
                <div className="flex items-start justify-center flex-col space-y-3">
                  <span
                    onClick={() => {
                      navigateto("/about");
                      scrolltotop();
                    }}
                    className="cursor-pointer border-b border-[#404BFD]"
                  >
                    About
                  </span>
                  <span
                    onClick={() => {
                      navigateto("/courses");
                      scrolltotop();
                    }}
                    className="cursor-pointer border-b border-[#404BFD]"
                  >
                    Plans
                  </span>
                  {/* <span onClick={()=>{navigateto("/contact"); scrolltotop();}}>Contact</span> */}
                  <span
                    onClick={() => {
                      navigateto("/privacyPolicy");
                      scrolltotop();
                    }}
                    className="cursor-pointer border-b border-[#404BFD]"
                  >
                    Privacy & Refund Policy
                  </span>
                  <span
                    onClick={() => {
                      navigateto("/terms&conditions");
                      scrolltotop();
                    }}
                    className="cursor-pointer border-b border-[#404BFD]"
                  >
                    Terms & Conditions
                  </span>
                </div>
              </div>
            </div>

            {/* <div className="f_lconn sm:pt-3">
                    <h3 className=''>Join Our Program</h3>
                    <div>
                       <div className="f_input flex items-center justify-star" id="f_mail">
                       <input type="text" placeholder='Your email address' name="" id='subs_mail' />
                        <label for="subs_mail" className='text-center' >Subscribe</label>
                       </div>
                        <div id='subs_msg'>* Will send you weekly updates for you better understanding and process.</div>
                    </div>
                </div> */}
          </div>

          {/* <div className="flex items-center ab:items-center justify-start pl-[15vw] ab:pl-0 pt-8 ab:justify-center w-full pb-3">
                <h2>Copyright &copy; 2024 All Rights Reserved.<br/> Made in India with ❤️ for the world </h2>
            </div> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
