export const tdata=[
    {
        "name":"ANUSHKA ",
        "batch":"JEE 2023 ASPIRANT",
        "review":" My experience with this mentorship has been great till now. I have received so much support from didi.  Earlier ! I used to be so much confused about which topics to cover first and from where to practice questions and how much time to devote to each chapter. She helps me in every possible way emotionally or be it academic. It was very difficult for me to be consistent in this time of covid. Every time I was unable to crack any question and feel depressed, she consoled me, motivated me to practice more a. I believe it was my right decision to take this mentorship program. Everyone should take this mentorship program for systematic and structured study.",
        "video_link":"https://www.youtube.com/embed/MYI_-XU4uJU"
    },
    {
        "name":"SAURAV",
        "batch":"IIT BHU",
        "review":" My name is SAURAV. It's been more than 50 days since I joined the mentorship program, and it helped me focus on my single target exam, JEE Advanced. At first, I was confused about my plans, what to study, what to skip. Prepare extra for other exams or not, but since I joined mentorship, I have focused on exact points to be covered for the exam and saved a lot of time from being distracted on non-important topics and exams. I am continuously working on the portions decided by Divyansh bhaiya, and I am looking forward to achieving a good rank in JEE",
        "video_link":"https://www.youtube.com/embed/EJOk2HXE8u0"
    },
     {
        "name":"ARPIT JAIN ",
        "batch":"JEE 2022 ASPIRANT",
        "review":" This Mentorship program is really worth it. I have faced a lot of problem earlier in self-study without Mentorship. Now my mentor daily gives me target that I follow strictly. Because there is always a pressure on me that I have to complete the target. So, it's really helpful.",
        "video_link":"https://www.youtube.com/embed/8vJofmm_LKo"
    },
    {
        "name":"SHRESTH SUDHANSHU",
        "batch":"",
        "review":"IITianBuddy mentorship is a very good platform if you are preparing for IIT JEE. Mentors are students of IITs and they are friendly like elder brother and sister. My mentor appreciates and motivates me for daily tasks. I am free to call him any time in a day. Overall, it's good experience to be a part of this mentorship",
        "video_link":"https://www.youtube.com/embed/mJfK94zgAbQ"
    }
]